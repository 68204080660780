import request from '@/utils/request'

//系统管理--管理员管理--分页查询管理员
export function getAdminList(data) {
  return request({
      url: '/admin_user/v1/list',
      method: 'post',
      data
  })
}

//系统管理--管理员管理--添加管理员
export function addAdmin(data) {
  return request({
      url: '/admin_user/v1/add',
      method: 'post',
      data
  })
}

//系统管理--管理员管理--删除管理员
export function deleteAdmin(data) {
  return request({
      url: '/admin_user/v1/delete',
      method: 'get',
      params:data
  })
}

//系统管理--管理员管理--管理员详情
export function getAdminDetail(data) {
  return request({
      url: '/admin_user/v1/detail',
      method: 'get',
      params:data
  })
}

//系统管理--管理员管理--更新管理员
export function editAdmin(data) {
  return request({
      url: '/admin_user/v1/edit',
      method: 'post',
      data
  })
}

//系统管理--管理员管理--查询所有管理员
export function getAllAdmin(data) {
  return request({
      url: '/admin_user/v1/listAll',
      method: 'post',
      data
  })
}