<template>
  <div class="user">
    <div class="line_two">
      <div class="buttonArea">
        <div class="add" @click="goAdd()">
          <i class="el-icon-plus"></i>
          新增管理员
        </div>
      </div>
      <el-table
        :data="dataUserList"
        style="width: 97%"
        class="data_list"
        v-loading="loading"
        :header-cell-style="getRowClass"
      >
        <el-table-column label="序号" width="100" align="center">
          <template slot-scope="scope">
            <div>{{ (queryInfo.current - 1) * queryInfo.size + 1 + scope.$index }}</div>
          </template>
        </el-table-column>
        <el-table-column label="头像" width="180" align="center">
          <template v-slot="{ row }">
            <div class="headPortraitImg">
              <img :src="row.headPortrait"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用户姓名" prop="userName" align="center" width="180" />
        <el-table-column label="角色" align="center" width="180" prop="roleName">
          <!-- <template v-slot="{ row }">
          </template> -->
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" align="center" width="180" />
        <el-table-column label="最后登录时间" prop="loginTime" align="center" width="180" />
        <el-table-column label="状态" prop="enable" align="center" width="100">
          <template v-slot="{ row }">
            <el-tooltip placement="top" content="启用或关闭">
              <el-switch
                @change="changeStatus(row)"
                v-model.number="row.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <div class="caoZuo">
              <div class="colorOne" style="margin-right: 10px" @click="goEdit(row)">编辑</div>
              <div class="colorOne red" @click="deleteOne(row.id)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加或修改 -->
    <el-dialog :visible.sync="dialogVisible.menu" width="600px" @close="handleCloseMenu" center :close-on-click-modal=false>
      <div class="tip">
        <div class="tipOne"></div>
        {{ dialogType ? '编辑管理员' : '新增管理员' }}
      </div>
      <el-form ref="menuObj" :model="menuObj" :rules="menuRules" label-width="110px">
        <el-form-item label="账号:" prop="userName">
          <el-input v-model="menuObj.userName" placeholder="请输入账号" class="inputItem" />
        </el-form-item>
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="menuObj.mobile" placeholder="请输入手机号" class="inputItem" />
        </el-form-item>
        <el-form-item label="默认密码:" v-if="!this.dialogType">
          <span>Admin123</span>
        </el-form-item>
        <el-form-item label="是否启用:" prop="enable">
          <el-radio-group v-model="menuObj.enable">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="角色:" prop="roleId">
          <el-radio-group v-model="menuObj.roleId">
            <el-radio :label="item.id" v-for="(item, index) in this.roleList" :key="index">{{
              item.roleName
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="头像" prop="headPortrait">
          <div class="ImgArea">
            <div class="showImg">
              <upload-image-one
                :image="menuObj.headPortrait"
                :imageShow="menuObj.headPortrait"
                :addTitleShow="false"
                @updateImgOne="updateImgOne"
              />
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm('menuObj')" v-if="dialogType">修改</el-button>
        <el-button type="primary" @click="submitForm('menuObj')" v-else>确定</el-button>
        <el-button @click="dialogVisible.menu = false">取消</el-button>
      </div>
    </el-dialog>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.current"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import UploadImageOne from '@/components/UploadImageOne.vue'
import { pagination } from '@/mixins/pagination'
import { getAdminList, deleteAdmin, addAdmin, editAdmin } from '@/api/admin.js'
import { getAllRole } from '@/api/role.js'
const defaultQueryInfo = Object.freeze({
  current: 1,
  size: 10,
  key: ''
})
export default {
  name: 'User',
  mixins: [pagination],
  components: { UploadImageOne },
  data() {
    return {
      dataUserList: [],
      detailList: [],
      selectionArr: [],
      loading: false,
      dataList: {},
      queryInfo: { ...defaultQueryInfo },
      dialogVisible: {
        menu: false,
        Permission: false
      },
      menuObj: {
        mobile: '',
        roleId: 0,
        sex: '0',
        userName: '',
        headPortrait: ''
      },
      roleList: [],
      imageUrlList: [],
      statusList: {},
      dialogType: false,
      menuRules: {
        roleName: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
        mobile:[{ required: true, message: '请输入手机号', trigger: 'change' }],
      }
    }
  },
  created() {
    this.$nextTick(()=>{
      this.search()
      this.getAllRoleList()
    })
  },
  methods: {
    log(row){
      // console.log(row)
    },
    submitForm(formName) {
      let reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if(!reg.test(this.menuObj.mobile)){
        this.$message.error('请输入正确的手机号!')
        return false
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.menuObj, 'menuObj')
          if(!this.dialogType){
            const { data: res } = await addAdmin(this.menuObj)
            if (res.resultCode === 200) {
              this.$message.success('添加成功!')
              this.dialogVisible.menu = false
            }
          }else{
            const { data: res } = await editAdmin(this.menuObj)
            if (res.resultCode === 200) {
              this.$message.success('修改成功!')
              this.dialogVisible.menu = false
            }
          }
           this.search()
        } else {
          this.$message.error('请填写必要的选项!')
          return false
        }
      })
    },
    handleCloseMenu() {
      this.menuObj = {}
    },
    // 新增
    goAdd() {
      this.dialogType = false
      this.dialogVisible.menu = true
    },
    async getAllRoleList() {
      const { data: res } = await getAllRole(this.queryInfo)
      this.roleList = res.data
    },
    // 编辑
    goEdit(row) {
      this.dialogType = true
      this.dialogVisible.menu = true
      this.menuObj ={
        mobile: row.mobile,
        roleId: row.roleId,
        sex: row.sex,
        userName: row.userName,
        headPortrait: row.headPortrait,
        id:row.id,
        enable: row.enable,
      }
    },
    //状态切换
    async changeStatus(row) {
      this.statusList ={
        mobile: row.mobile,
        roleId: row.roleId,
        sex: row.sex,
        userName: row.userName,
        headPortrait: row.headPortrait,
        id:row.id,
        enable:row.enable
      }
      // console.log(this.statusList);
      await editAdmin(this.statusList)
      this.$message.success('修改成功')
      this.search()
    },
    //重置查询条件
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10
      }
      this.search()
    },
    async search() {
      this.loading = true
      const { data: res } = await getAdminList(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    async deleteOne(id) {
      this.$confirm(`确认删除该管理员吗 ?`, {
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await deleteAdmin({id:id})
          if (res.resultCode === 200) {
            this.$message.success(`删除成功`)
            this.search()
          } else {
            this.$message.error('删除失败，请重试。')
          }
        })
        .catch(() => {})
    },
    updateImgOne(val) {
      this.menuObj.headPortrait = val
      // console.log()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-radio{
  width: 90px;
  margin-top: 15px;
}
/deep/ .v-modal{
  background: #fff !important;
}
.headPortraitImg{
  margin: 0px auto;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
  }
}
.inputItem {
  width: 350px;
}
.tip {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  display: flex;
  margin: -25px 0px 20px 25px;
  .tipOne {
    margin: 3px 6px 0px 0px;
    background: #7c71e1;
    border-radius: 11px;
    width: 4px;
    height: 18px;
  }
}
.colorOne {
  color: #7c71e1;
  cursor: pointer;
}
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_two {
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .buttonArea {
      display: flex;
      margin-top: 20px;
      div {
        width: 120px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }
      .add {
        background: #7c71e1;
        color: #ffffff;
      }
    }
  }
  .caoZuo {
    display: flex;
    justify-content: center;
    .colorOne {
      font-size: 12px;
      color: #7c71e1;
      cursor: pointer;
    }
    .red {
      color: #ff2828;
    }
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }
}
</style>
